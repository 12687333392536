@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.noWrap2 {
    display: 'block';
    display: -webkit-box;
    line-height: 1.5;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  	-webkit-box-orient: vertical;
}

.leftShadowTable {
  box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
  -webkit-box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
  -moz-box-shadow: -9px 0px 12px -1px rgba(0,0,0,0.07);
}

.rightShadowTable {
  box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  -webkit-box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  -moz-box-shadow: 12px 0px 12px -1px rgba(0,0,0,0.07)!important;
  background-color: #fff;
}

.removeShadowTable {
  transition: linear .3s;
  box-shadow: none;
}

.cloudSync {
  animation: rotation 2s infinite linear;
  font-size: 20px;
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
  }