.animate-loading {
    font-size: 40;
    animation: zoomin 1s infinite alternate;
}

@keyframes zoomin {
    from {
      transform: scale(1.5);
    }
    to {
      transform: scale(2.5);
    }
  }